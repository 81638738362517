<template>
  <div id="beneficiary-select">
    <b-form-group class="form-group">
      <label for="beneficiary" id="title-styles" v-if="label">
        {{label}}
        <span v-if="optional" id="help" class="help">
          (opcional)
        </span>
      </label>
      <div id="multiselect-container">
        <multiselect
          id="beneficiary"
          v-model="beneficiary"
          track-by="id"
          label="name"
          placeholder="Selecionar"
          :options="beneficiariesOptions"
          @select="onSelect"
          @search-change="el => debounceGetBeneficiaries(el)"
          :searchable="true"
          :allow-empty="true"
          :showLabels="false"
          :showNoResults="true"
          :loading="isLoading"
          class="with-border"
          :disabled="disabled || onlendingExtract"
          >
            <template slot="caret">
              <div v-if="beneficiary && !onlendingExtract" class="close-icon">
                <b-button variant="default" @click="!onlendingExtract ? onSelect(null) : null" />
              </div>
              <div v-else class="chevron">
                <v-chevron-down class="icon" />
              </div>
            </template>
    
            <template  slot="singleLabel" slot-scope="{ option }">
                <div class="option">
                  <div>{{ option.name }}</div>
                </div>
            </template>
    
            <template slot="singleLabel" slot-scope="{ option }">
              <span v-if="optionsPrepend">
                <span class="options-prepend">{{optionsPrepend}}: </span>
                {{ option.name }}
              </span>
              <span v-else>{{ option.name }}</span>
            </template>
    
            <template slot="option" slot-scope="props">
              <span v-if="optionsPrepend">
                <span class="options-prepend">{{optionsPrepend}}: </span>
                {{ props.option.name }}
              </span>
              <span v-else>{{ props.option.name }}</span>
            </template>
    
            <template slot="noOptions">
              <div>Digite para pesquisar o favorecido.</div>
            </template>
    
            <template slot="noResult">
              <li v-if="optionsPrepend" @click="openAddBeneficiary()">
                <div class="custom-item">
                  <v-plus class="icon" />
                  Adicionar novo favorecido
                </div>
              </li>
    
              <div v-else>Nenhum favorecido encontrado.</div>
            </template>
        </multiselect>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'

export default {
  props: {
    value: Object,
    options: {
      type: Array,
      default() { return [] }
    },
    optional: Boolean,
    optionsPrepend: String,
    label: String,
    openAddBeneficiary: Function,
    disabled: Boolean,
    noPaddingTop: Boolean,
    onlendingExtract: Boolean,
    onlendingRule: Boolean
  },
  components: {
    'v-chevron-down': ChevronDown,
    'v-plus': Plus
  },
  mounted() {
    this.debounceGetBeneficiaries = debounce(this.getBeneficiaries, 500)
    this.setSelectedDefault(this.value)
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      beneficiary: null,
      beneficiaries: [],
      debounceGetBeneficiaries: null,
      isLoading: false
    }
  },
  computed: {
    beneficiariesOptions(){
      return this.isLoading ? [] : this.beneficiaries;
    }
  },
  methods: {
    setSelectedDefault(beneficiary) {
      if (beneficiary)
        this.beneficiary = beneficiary
    },
    onSelect(beneficiary) {
      this.beneficiary = beneficiary ?? null;
      this.$emit('input', this.beneficiary);
      this.$emit('select', this.beneficiary);
    },
    async getBeneficiaries(query) {
      if (query && query.length > 2) {
        try {
          const self = this;
          self.isLoading = true
          if(this.onlendingRule){
            await self.api.getBeneficiariesWithRules(self.clinic.id, { name: query }).then(({data}) => {
              self.beneficiaries = data.map(res => res)
            }).finally(() => {self.isLoading = false})
          } else {
            await self.api.getBeneficiaries(self.clinic.id, { name: query }).then(({data}) => {
              self.beneficiaries = data.map(res => res)
            }).finally(() => {self.isLoading = false})
          }
        } catch (error) {
          this.$toast.error(error.message)
          throw new Error(400)
        }
      }
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (newValue && !oldValue) {
        if (!this.beneficiaries.find(p => p.id === newValue.id)) {
          this.beneficiaries.push(newValue);
        }
      }
      this.beneficiary = newValue;
    },
    options: function(){
      this.beneficiaries = this.options
    }
  }
}

</script>

<style lang="scss">
#beneficiary-select {
  .form-group{
    width: 100%;
    margin: 0;
  }
  
  .close-icon {
    width: 24px;
    height: 24px;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    position: absolute;
    transition: all 500ms;
    cursor: pointer;
    z-index: 1; 
  
    svg {
      fill: var(--neutral-500);
      stroke: none !important;
    }
  
  }
  .option {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  
    svg {
      fill: var(--neutral-500);
    }
  }
  
  .icon {
    width: 25px;
    transition: all 500ms;
  }
  
  .options-prepend{
    color: var(--type-placeholder);
  }
  
  .custom-item{
    color: var(--blue-500);
  }
  .padding-onlending{
    padding-top: 20px;
    padding-right: 30px;
  }

  #multiselect-container .multiselect .multiselect__tags .multiselect__spinner {
    transform: scale(0.8);
    margin-right: 7px;
  }


}
</style>
